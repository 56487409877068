<template>
	<div class="validate-user">
		<div class="validate-user__header">
			<v-btn class="validate-user__header-button" icon color="#575E60" @click="frompage ? $router.back() : $router.push({ name: 'Home' })">
				<v-icon class="validate-user__header-button-icon">far fa-arrow-left</v-icon>
			</v-btn>
			<h2 v-if="verified" class="validate-user__header-title">{{ $t('validateUser.successTitle') }}</h2>
			<h2 v-else class="validate-user__header-title">{{ $t('validateUser.errorTitle') }}</h2>
		</div>
		<div class="validate-user__body">
			<h3 v-if="verified" class="validate-user__header-subtitle">{{ $t('validateUser.successSubtitle') }}</h3>
			<h3 v-else class="validate-user__header-subtitle">{{ $t('validateUser.errorSubtitle') }}</h3>
			<div v-if="verified" class="validate-user__body-icon" style="color: green"><i class="fad fa-check-circle"></i></div>
			<div v-else class="validate-user__body-icon" style="color: red"><i class="fad fa-times-circle"></i></div>
			<v-btn v-if="verified" class="validate-user__body-button" color="#2498ff" :to="{ name: 'Login' }" depressed>
				{{ $t('menu.login') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
import { validateUser } from '@/api/sessions.js';

export default {
	name: 'ValidateUser',

	props: {
		frompage: Boolean
	},

	data() {
		return {
			verified: false
		};
	},

	mounted() {
		this.checkIfUserHasToken();
		this.validateUser();
	},

	methods: {
		checkIfUserHasToken() {
			if (!this.$route.query.validationToken) {
				this.$router.push({ name: 'Home' });
			}
		},

		validateUser: async function () {
			if (this.$route.query.validationToken) {
				const data = await validateUser(this.$route.query.validationToken);
				data.request.response === 'true' ? (this.verified = true) : (this.verified = false);
			}
		}
	}
};
</script>


<style lang="scss" scoped>
.validate-user {
	background: #fff;
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.05);
	border-radius: 12px;
	padding: 2rem;
}

.validate-user__header {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	&-button {
		position: absolute;
		left: 0;
		width: 1rem;
		height: 1rem;

		&-icon {
			font-size: 1rem !important;
		}
	}

	&-title {
		font-family: Cabin;
		font-weight: bold;
		font-size: 1.625rem;
		color: #293133;
	}

	&-subtitle {
		font-family: Cabin;
		font-weight: normal;
		text-align: center;
		font-size: 1rem;
		color: #293133;
		padding-top: 1rem;
	}
}

.validate-user__body {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&-icon {
		font-size: 10rem;
	}

	&-button {
		width: 100%;
		color: #fff;
	}
}
</style>
